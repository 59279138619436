import React from "react";
import logo from "./media/logo.svg";
import angelLogo from "./media/angelicon.svg";
import "./App.scss";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="simple-landing">
        <div className="logo-landing">
          <img src={logo} className="logo-svg mb-5" />
          <div className="mt-2">
            <a
              className="transition-all playfair p-3"
              href="https://angel.co/t-bird-capital/syndicate"
              target="_blank"
            >
              Invest with us
              <img
                className="linkedin-logo transition-all ml-1"
                src={angelLogo}
              />
            </a>
          </div>
          <p className="playfair mt-5">
            © T-Bird Capital {new Date().getFullYear()}
          </p>
        </div>
      </div>
    );
  }
}

export default App;
